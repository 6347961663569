.title-link {
    color: inherit;
    text-decoration: inherit;
}

.main-title {
    text-align: center;
    font-size: 3vh;
    margin-top: 1vh;
}

.sub-title {
    text-align: center;
    font-style: italic;
    font-size: 1.25vh;
    font-weight: lighter;
    margin-top: 0.5vh;
}
