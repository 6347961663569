.video-box {
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    color: #DAE3E4;
}

.movie {
    font-size: xx-large;
    margin-bottom: 5px;
}

.details-row {
    display: flex;
    margin-bottom: 5px;
    margin-left: 2px;
}

.details-title {
    margin-right: 5px;
    margin-top: auto;
    color: #a6acad;
}

.details-content {
    font-size: larger;
    margin-top: auto;
}

.video {
    max-width: 100%;
    max-height: 576px;
    cursor: pointer;
}
