.tab-selector-row {
    margin-top: 1.25vh;
    margin-bottom: 1.25vh;
    text-align: center;
}

.tab-button {
    margin-left: 15px;
    margin-right: 15px;
    height: 2.5vh;
    font-size: 1.5vh;
    cursor: pointer;
}

.tab-button-selected {
    margin-left: 15px;
    margin-right: 15px;
    height: 2.5vh;
    font-size: 1.5vh;
    background-color: #105A61;
    color: #DAE3E4;
}
