.header-box {
    background-color:#105A61;
    color:white;
    overflow: hidden;
    position: absolute;
    top: 0px;
    height: 8vh;
    left: 0px;
    right: 0px;
}

.content-box {
    overflow: hidden;
    position: absolute;
    top: 8vh;
    bottom: 2vh;
    left: 0px;
    right: 0px;
}

.footer-box {
    overflow: hidden;
    position: absolute;
    bottom: 0px;
    height: 2vh;
    left: 0px;
    right: 0px;
}