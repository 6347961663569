.group-selector-box {
    display: flex;
    flex-direction: column;
    width: 15%;
    overflow: auto;
    height: 90vh;
    direction: rtl;
}

.group-header {
    direction: ltr;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 5px;
    padding-right: 5px;
    background-color: #DAE3E4;
    cursor: pointer;
    border-width: 0px;
    text-align: left;
}

.group-header-selected {
    direction: ltr;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
    padding-right: 15px;
    background-color: #105A61;
    color: white;
    border-width: 0px;
    text-align: left;
}

.group-header-title {
    font-size: large;
}

.group-header-count {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
}